<template>
	<div>
		<v-simple-table>
			<thead>
				<tr>
					<th>&nbsp;</th>
					<th
						v-for="col in answerOptionsOrder"
						:key="col"
						class="text-center"
					>
						{{ answerOptions[col].text }}
					</th>
				</tr>
			</thead>
			<tbody>
				<v-item-group
					v-for="row in subquestions"
					tag="tr"
					:key="row.value"
					v-model="value[row.value]"
					:multiple="multiple"
					:disabled="isDisabled"
				>
					<th>{{ row.text }}</th>
					<td
						v-for="col in answerOptionsOrder"
						:key="`${row.value}-${answerOptions[col].value}`"
					>
						<v-item
							v-slot="{ active, toggle }"
							:value="answerOptions[col].value"
						>
							<v-card
								flat
								:color="active ? 'success' : 'white'"
								:dark="active ? true : false"
								class="mw-option-col pa-4 text-center"
								transparent
								@click="isDisabled ? $emit('warn') : toggle()"
							>
								{{ answerOptions[col].text }}
							</v-card>
						</v-item>
					</td>
				</v-item-group>
			</tbody>
		</v-simple-table>
	</div>
</template>

<style lang="less">
@import "../../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
export default {
	name: "MatrixDesktop",
	props: {
		value: {},
		answerOptionsOrder: { type: Array },
		subquestions: { type: Array },
	},
	computed: {
		isDisabled() {
			return this.disabled || this.question.disable_input;
		},
	},
	inject: ["question", "disabled", "multiple", "answerOptions"],
};
//
</script>
"
