<template>
	<div class="pt-5">
		<matrix-desktop
			v-if="!isMobile"
			v-model="selected"
			@warn="warn"
			:subquestions="subquestions"
			:answer-options-order="answerOptionsOrder"
		></matrix-desktop>
		<matrix-mobile
			v-else
			v-model="selected"
			@warn="warn"
			:subquestions="subquestions"
			:answer-options-order="answerOptionsOrder"
		></matrix-mobile>
	</div>
</template>

<style lang="less">
@import "../../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
import MatrixDesktop from "@c/survey/render/questionTypes/MatrixDesktop";
import MatrixMobile from "@c/survey/render/questionTypes/MatrixMobile";

export default {
	name: "Matrix",
	props: {
		value: {},
		answerOptionsOrder: { type: Array },
		subquestions: { type: Array },
	},
	data: () => {
		return {
			selected: {},
		};
	},
	components: {
		MatrixDesktop,
		MatrixMobile,
	},
	watch: {
		selected: {
			deep: true,
			handler() {
				this.$emit("update", this.selected);
			},
		},
		value: {
			immediate: true,
			handler(data) {
				if (data) {
					this.selected = data;
				}
			},
		},
	},
	methods: {
		warn() {
			this.$emit("error");
		},
	},
	inject: ["isMobile"],
};
</script>
