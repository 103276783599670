<template>
	<div>
		<v-item-group
			v-for="row in subquestions"
			:key="row.value"
			v-model="value[row.value]"
			:multiple="multiple"
			:disabled="isDisabled"
		>
			<p class="subtitle-2">{{ row.text }}</p>
			<div
                class="mb-3"
				v-for="col in answerOptionsOrder"
				:key="`${row.value}-${answerOptions[col].value}`"
			>
				<v-item
					v-slot="{ active, toggle }"
					:value="answerOptions[col].value"
				>
					<v-card
						flat
						:color="active ? 'success' : 'white'"
						:dark="active ? true : false"
						class="mw-option-col pa-4 text-center mt-2"
						transparent
						@click="isDisabled ? $emit('warn') : toggle()"
					>
						{{ answerOptions[col].text }}
					</v-card>
				</v-item>
			</div>
		</v-item-group>
	</div>
</template>

<style lang="less">
@import "../../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
export default {
	name: "MatrixMobile",
	props: {
		value: {},
		subquestions: { type: Array },
		answerOptionsOrder: {}
	},
	computed: {
		isDisabled() {
			return this.disabled || this.question.disable_input;
		},
	},
	inject: [
		"question",
		"disabled",
		"multiple",
		"answerOptions",
	],
};
//
</script>
"
